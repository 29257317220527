import Vue from 'vue'
import axiosA from 'axios'

const baseURL = 'https://admin.aner.vip/api/'
const https = axiosA.create({
    baseURL,
    header: {
        'Content-Type': 'application/json;charset=utf-8'
    }
})
export const axios = function (method, url, data) {
    return new Promise((resolve, reject) => {
        https({
            url,
            method,
            data
        }).then((res) => {
            // 此处作用很大，可以扩展很多功能。
            // 比如对接多个后台，数据结构不一致，可做接口适配器
            // 也可对返回日期/金额/数字等统一做集中处理
            if (res.status === 200) {
                
                resolve(res.data);
            } else {
                // 通过配置可关闭错误提示
                
                reject(res);
            }
        }).catch((error) => {

        }).finally(() => {
           
           
        })
    })
}